<template>
    <div class="font-bold text-center p-4 text-white">
        Page designed by Federico Brenner
    </div>
</template>

<script>
export default {
  name: 'Simple-Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>